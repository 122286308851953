window.$ = require('jquery');
window.jQuery = window.$;

$(document).ready(function() {

    $('.hamburger').addClass('full');

	var topImg = $('.top-img');

	var scroll = $(window).scrollTop();
	/* if ( scroll >= 1 && !logo.hasClass('large') ) {
        topImg.addClass('grayscale');
    } */

/*     $(window).scroll(function() {

        scroll = $(window).scrollTop();
        if ( scroll >= 1 ) {
            topImg.addClass('grayscale');
        } else if ( scroll < 1  ) {
        	topImg.removeClass('grayscale');
        }
    }); */

    $('nav').on('click', function(){
    	$('.hamburger').toggleClass('open');
    	$('.menu').toggleClass('active');
    	$('nav').toggleClass('active');
    });
});